<template>
  <router-view/>
</template>

<script>
export default {
  name: 'App',
  setup(){
    return{

    }
  }
}
</script>

<style scoped>

@import '../public/icon/icon.css';
@import '../public/icon/AdminIcon.css';
@import '../public/icon/public.css';

    /* 设置滚动条的样式 */
    body::-webkit-scrollbar {
    width:10px;
    height: 10px;
 }
 
 /* 滚动槽 */
 body::-webkit-scrollbar-track {
 -webkit-box-shadow:inset006pxrgba(0,0,0,0.3);
 border-radius:10px;
 }
 
 /* 滚动条滑块 */
 body::-webkit-scrollbar-thumb {
 border-radius:10px;
 background:rgba(0,0,0,0.1);
 -webkit-box-shadow:inset006pxrgba(0,0,0,0.5);
 }
 
 body::-webkit-scrollbar-thumb:window-inactive {
 background:rgba(224, 212, 212, 0.4);
 }
</style>
